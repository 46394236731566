import React from 'react';
import "./portfolios.scss";
import shapeImg_01 from "../../images/shape/shape_10.png";


import portfolioImg_01 from "../../images/portfolio_1.jpg"
import portfolioImg_02 from "../../images/portfolio_2.jpg"
import portfolioImg_03 from "../../images/portfolio_3.jpg"

// AI Images
import ai_1 from '../../images/ai/ai_1.png';
import ai_2 from '../../images/ai/ai_2.png';
import ai_3 from '../../images/ai/ai_3.png';
import ai_4 from '../../images/ai/ai_4.png';
import ai_5 from '../../images/ai/ai_5.png';

// BTP Images
import btp_1 from '../../images/btp/btp_1.png';
import btp_2 from '../../images/btp/btp_2.png';
import btp_3 from '../../images/btp/btp_3.png';
import btp_4 from '../../images/btp/btp_4.png';
import btp_5 from '../../images/btp/btp_5.png';
import btp_6 from '../../images/btp/btp_6.jpg';

// Mobile Images
import mobile_1 from '../../images/mobile/mobile_1.png';
import mobile_2 from '../../images/mobile/mobile_2.png';
import mobile_3 from '../../images/mobile/mobile_3.png';
import mobile_4 from '../../images/mobile/mobile_4.png';

// SAP Cloud Images
import cloud_1 from '../../images/sapcloud/cloud_1.jpeg';
import cloud_2 from '../../images/sapcloud/cloud_2.jpeg';
import cloud_3 from '../../images/sapcloud/cloud_3.jpg';


import { Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PortfolioItem from "../../SingleFeatures/portfolio_item";
import SectionTitle from "../section_title";
export default function Index() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: "30px"
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className="operinova_project project_v1">
            <div className="shape_v3">
                <img src={shapeImg_01} className="shape_10" alt="shape"/>
            </div>
            <div className="container">
                <SectionTitle 
                    title="Our Latest Projects"
                    description=" We always are committed with the goal customer for offer you the best solutions,faster and hight quality"
                />
                <div className="row">
                    <div className="col-lg-12 carousel-style projects_slider_content">
                        <Tabs defaultActiveKey="btp" id="portfolio-tabs">
                            <Tab eventKey="btp" title="SAP BTP" className="project_btn active_btn">
                                <div className="mt-5">
                                    <Slider {...settings}>
                                        <div>
                                            <PortfolioItem
                                              img={btp_1}
                                              title="Sap Transport"
                                              tag="Routing"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                              img={btp_2}
                                              title="Design & Develop"
                                              tag="Design"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem 
                                             img={btp_3}
                                             title="Warehouse Management"
                                             tag="Logistics"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={btp_4}
                                                title="Shopping App"
                                                tag="Deals & Shipping"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={btp_5}
                                                title="KPI Dashboard"
                                                tag="Deals & Shipping"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={btp_6}
                                                title="Sap Build"
                                                tag="Integration & Development"
                                            />
                                        </div>
                                    </Slider>
                                </div>
                            </Tab>
                            <Tab eventKey="ai" title="Artificial intelligence (AI)" className="project_btn active_btn">
                                <div className="mt-5">
                                    <Slider {...settings}>
                                        <div>
                                            <PortfolioItem
                                                img={ai_1}
                                                title="Chatbot"
                                                tag="AI Assistant"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={ai_2}
                                                title="Performance Prediction"
                                                tag="Productivity"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={ai_3}
                                                title="Computer Vision"
                                                tag="Vision , Recognition & Automation"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={ai_4}
                                                title="Tag Tracking"
                                                tag="Analysis & Tracking"
                                            />
                                        </div>

                                        <div>
                                            <PortfolioItem
                                                img={ai_5}
                                                title="Design & Develop Model Prediction"
                                                tag="Data Engineering"
                                            />
                                        </div>
                                    </Slider>
                                </div>
                            </Tab>
                            <Tab eventKey="sapcloud" title="Cloud" className="project_btn active_btn">
                                <div className="mt-5">
                                    <Slider {...settings}>
                                        <div>
                                            <PortfolioItem
                                                img={cloud_1}
                                                title="Integration"
                                                tag="Integration suite"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={cloud_2}
                                                title="Cross-Platform "
                                                tag="Architecture & Development"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={cloud_3}
                                                title="Multi-Tecnology"
                                                tag="Sap - Cloud"
                                            />
                                        </div>
                                    </Slider>
                                </div>
                            </Tab>
                            <Tab eventKey="cloud" title="Mobile" className="project_btn active_btn">
                                <div className="mt-5">
                                    <Slider {...settings}>
                                        <div>
                                            <PortfolioItem
                                                img={mobile_1}
                                                title="Internet of Things"
                                                tag="Layout & Integration"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={mobile_2}
                                                title="Developing"
                                                tag="Coding"
                                            />
                                        </div>
                                        <div>
                                            <PortfolioItem
                                                img={mobile_3}
                                                title="Design & Develop"
                                                tag="Cross-Platform"
                                            />
                                        </div>

                                        <div>
                                            <PortfolioItem
                                                img={mobile_4}
                                                title="Payment Gateway"
                                                tag="Easy Payment"
                                            />
                                        </div>
                                        
                                    </Slider>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </section>
    )
}
