import React from 'react';
import "./services.scss";
import ServiceItem from "../../SingleFeatures/service_item";
import SectionTitle from "../section_title";
import serviceIcon_01 from "../../images/icon_1.png";
import serviceIcon_02 from "../../images/icon_2.png";
import serviceIcon_03 from "../../images/icon_3.png";

export default function Index() {
    return (
        <section className="operinova_service service_v1">
            <div className="shape_v2">
                <img src="assets/images/shape/shape_7.png" className="shape_7" alt="" />
            </div>
            <div className="container">
                <SectionTitle
                    title="Our Service"
                    description="We highlight the most popular services"
                />
                <div className="row">
                    <ServiceItem 
                      icon={serviceIcon_01}
                      title="SAP BTP"
                      url="#"
                      description="Design, Development and Deployment Apps with tecnology Mobile,Web,Backend"
                    />
                    <ServiceItem
                        icon={serviceIcon_02}
                        title="Artificial intelligence"
                        url="#"
                        description="Collect, Analisys an Modelling data using Machine Learnig,Deep Learnig,LLM,Computer vision and GenAI."
                    />
                    {/* <ServiceItem
                        icon={serviceIcon_03}
                        title="Blockchain"
                        url="#"
                        description="Desing and development  of Simple Contract,Contract Fabric an deployment en Blockchain WEB3"
                    /> */}

<ServiceItem
                        icon={serviceIcon_03}
                        title="SAP Cloud"
                        url="#"
                        description="Clean Code and SAP Rise to elevate your cloud solutions. Our expert team specializes in delivering innovative"
                    />
                </div>
            </div>
        </section>                 
    )
}
